.viewport {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-attachment: fixed;
}

.viewport img {
    width: 180px;
    height: 132px;
}
