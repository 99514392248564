.App {
}

.App-logo {
    animation-name: App-logo-spin;
    animation-duration: 3s;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
    }
}

.container-fluid {
    background-color: #176347;
}

.App-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.App-header img {
    width: 180px;
}

.App-header nav ul {
    display: flex;
    list-style: none;
    line-height: normal;
}

.App-header nav ul li {
    line-height: normal;
    position: relative;
}

.navbar-light .navbar-nav .active > .nav-link {
    color: #FFFFFF;
}

.navbar-light .navbar-nav .nav-link {
    color: #FFFFFF;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #FFFFFF;
}

.navbar-toggler {
    border: none;
}

.App-header nav ul li a {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 200;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 20px;
    text-align: left;
    text-decoration-style: solid;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

.App-link {
}

@keyframes App-logo-spin {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
